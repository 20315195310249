import React, { ReactElement } from 'react';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';

import { useStore } from 'src/mst/StoreProvider';
import { CustomAutocomplete } from 'src/components/fields';

import { useSizes } from './useSizes';
import { useSizeFilterStyles } from './SizeFilterStyles';

interface SizeFilterProps {
    label: string;
    id: string;
    name: string;
    languageNamespaces: string[];
    placeholder?: string;
    popperMinWidth?: number;
}

export const SizeFilter = observer(
    ({
        name,
        languageNamespaces,
        label,
        placeholder,
        popperMinWidth,
    }: SizeFilterProps): ReactElement => {
        const classes = useSizeFilterStyles();

        const { menuId, subCategoryId } = useParams();

        const { productsList } = useStore();
        const { data: options } = useSizes({
            name,
            subCategoryId,
            targetGroupId: menuId,
        });

        const currentValues = productsList
            .getFilterValues(name)
            .map((item) => item.value);

        const handleChange = (name: string, values: string[]): void => {
            if (Array.isArray(options) && options?.length > 0) {
                const mappedValues = values.map((value) => {
                    const foundValue = options?.find((item) => item.value === value);
                    return {
                        filter: name,
                        titles: [() => foundValue?.title || ''] || [],
                        value: foundValue?.value || '',
                    };
                });
                productsList.setFilterValues(name, mappedValues);
            }
        };

        return (
            <div className={classes.filterContainer}>
                <CustomAutocomplete
                    multiple
                    tLabelKey={label}
                    nameSpaces={languageNamespaces}
                    name={name}
                    tPlaceholderKey={placeholder}
                    items={options ?? []}
                    value={currentValues}
                    handleChange={handleChange}
                    popperMinWidth={popperMinWidth}
                />
            </div>
        );
    }
);
