import React, { ReactElement } from 'react';
import { Link, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MenuList, MenuItem } from '@mui/material';

import { AppRoutes } from 'src/routing/appRoutes';

import { NavMenuItem } from './NavMenuItem';
import { useMainProductNavigationMenuStyles } from './MainProductNavigationMenuStyles';

interface MainNavigationMenuDetailedCategoriesProps {
    items?: NavMenuItem[];
    selectedSubcategory: string;
    selectedMenuId: string;
}

export const MainNavigationMenuDetailedCategories = ({
    items,
    selectedSubcategory,
    selectedMenuId,
}: MainNavigationMenuDetailedCategoriesProps): ReactElement => {
    const classes = useMainProductNavigationMenuStyles({});
    const { t } = useTranslation(['common']);
    return (
        <MenuList
            className={classes.detailedCategoriesList}
            id='detailedCategories'
            role='menu'
            aria-labelledby={selectedSubcategory}
        >
            {items?.map((item) => (
                <MenuItem
                    tabIndex={-1}
                    disableRipple
                    key={item.id}
                    className={classes.detailedCategoryListItem}
                >
                    <Link
                        to={
                            item.id !== 'All'
                                ? generatePath(AppRoutes.goodsListSubCategory, {
                                      menuId: selectedMenuId,
                                      categoryId: selectedSubcategory,
                                      subCategoryId: item.id,
                                  })
                                : generatePath(AppRoutes.goodsListCategory, {
                                      menuId: selectedMenuId,
                                      categoryId: selectedSubcategory,
                                  })
                        }
                        tabIndex={0}
                    >
                        {t(item.title)}
                    </Link>
                </MenuItem>
            ))}
        </MenuList>
    );
};
