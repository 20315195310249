import React, { ReactElement } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { AppRoutes } from 'src/routing/appRoutes';
import { Breadcrumps, BreadcrumpsItem } from 'src/components/base';
import { useStore } from 'src/mst/StoreProvider';

type ProductBreadcrumpItem = { id: string; title: string };

const langNs = ['common'];

const generateBreadCrumps = (
    menuInfo: ProductBreadcrumpItem,
    categoryInfo: ProductBreadcrumpItem,
    subCategoryInfo: ProductBreadcrumpItem
): BreadcrumpsItem[] => {
    const breadcrumps = [{ key: '1', title: 'common:home', href: `${AppRoutes.home}/` }];

    if (menuInfo.id) {
        breadcrumps.push({
            key: menuInfo.id,
            title: menuInfo?.title,
            href: generatePath(AppRoutes.goodsListMenu, {
                menuId: menuInfo.id,
            }),
        });
    }
    if (categoryInfo.id) {
        breadcrumps.push({
            key: categoryInfo.id,
            title: categoryInfo.title,
            href: generatePath(AppRoutes.goodsListCategory, {
                menuId: menuInfo.id,
                categoryId: categoryInfo.id,
            }),
        });
    }

    if (subCategoryInfo.id) {
        breadcrumps.push({
            key: subCategoryInfo.id,
            title: subCategoryInfo.title,
            href: generatePath(AppRoutes.goodsListSubCategory, {
                menuId: menuInfo.id,
                categoryId: categoryInfo.id,
                subCategoryId: subCategoryInfo.id,
            }),
        });
    }

    return breadcrumps;
};

export const GoodsListBreadcrumps = observer((): ReactElement => {
    const { menuId, categoryId, subCategoryId } = useParams();
    const { navigation } = useStore();

    const menuTitle = navigation.getMenuTitleById(menuId || '');
    const categoryTitle = navigation.getCategoryTitleById(categoryId || '');
    const subCategoryTitle = navigation.getSubcategoryTitleById(subCategoryId || '');
    const breadcrums = generateBreadCrumps(
        { id: menuId || '', title: menuTitle },
        { id: categoryId || '', title: categoryTitle },
        { id: subCategoryId || '', title: subCategoryTitle }
    );

    return <Breadcrumps breadcrumps={breadcrums} namespaces={langNs} />;
});
