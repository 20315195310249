import { useQuery, UseQueryResult } from 'react-query';
import qs from 'query-string';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { SelectableItem } from 'src/shared/types';
import { SelectionValues } from 'src/shared/constants';

interface useSizesParams {
    targetGroupId: string;
    name: string;
    subCategoryId?: string;
}

interface useSizesQueryParams {
    targetGroupId: string;
    itemSubcategoryId?: string;
}

export const useSizes = ({
    name,
    targetGroupId,
    subCategoryId,
}: useSizesParams): UseQueryResult<SelectableItem[]> => {
    const httpClient = useHttpClient();

    return useQuery(
        [name, targetGroupId, subCategoryId],
        async (): Promise<SelectableItem[]> => {
            const path =
                name === SelectionValues.sizeShoes
                    ? 'web-shop/shoes/item-sizes'
                    : 'web-shop/item-sizes';
            if (path && name) {
                const queryParams: useSizesQueryParams = {
                    targetGroupId,
                };
                if (name !== SelectionValues.sizeShoes && subCategoryId) {
                    queryParams.itemSubcategoryId = subCategoryId;
                }
                const data = await httpClient.get(`${path}?${qs.stringify(queryParams)}`);

                if (Array.isArray(data)) {
                    return data.map((item) => ({ title: item.label, value: item.value }));
                }
                return [];
            }
            return [];
        },
        {
            refetchOnWindowFocus: false,
        }
    );
};
