import { IStoreSnapshotOut } from './models';
import { storageKeys } from 'src/shared/constants';

export const defaultStoreState: IStoreSnapshotOut = {
    globalSearchValue: '',
    shopsList: {
        list: [],
        total: 0,
    },
    webshops: {
        list: [],
    },
    bookingReservationList: {
        list: [],
    },
    reservationHistory: {
        list: null,
        total: null,
        page: 0,
        rowsPerPage: 10,
    },
    purchasedItemsHistory: {
        page: 0,
        rowsPerPage: 10,
        total: null,
        list: [],
    },
    bookingPurchaseList: {
        list: JSON.parse(localStorage.getItem(storageKeys.purchaseItems)) || [],
        isIncompleteOrder: false,
        itemReference: null,
    },
    wishList: {
        list: JSON.parse(localStorage.getItem(storageKeys.favoriteItems)) || [],
    },
    user: {
        id: null,
        archived: null,
        active: null,
        roles: null,
        name: null,
        firstname: null,
        email: null,
        phoneNumber: null,
        mobileNumber: null,
        activated: null,
        street: null,
        houseNumber: null,
        zipCode: null,
        city: null,
        country: null,
        shippingAddressName: null,
        shippingAddressFirstName: null,
        shippingAddressStreet: null,
        shippingAddressHouseNumber: null,
        shippingAddressZipCode: null,
        shippingAddressCity: null,
        yearOfBirth: null,
        newsletter: null,
        salutation: null,
    },
    authentication: {
        isTokensLoading: false,
        authModule: null,
        isAuth: Boolean(
            localStorage.getItem('accessToken') && localStorage.getItem('refreshToken')
        ),
    },
    productsList: {
        filters: [],
        sale: false,
        sorting: null,
        items: [],
        count: 0,
    },
    productDetails: {
        id: null,
        name: null,
        brand: null,
        material: null,
        color: null,
        price: null,
        minimumStock: null,
        userStock: null,
        state: null,
        sizes: null,
        shippingAvailable: null,
        webShopId: null,
        webShopName: null,
        shippingCosts: null,
        freeOfShippingCosts: null,
        previewImage: null,
        images: null,
        imagesSrc: null,
        targetGroup: null,
        targetGroupId: null,
        category: null,
        categoryId: null,
        subCategory: null,
        subCategoryId: null,
        additionalInformation: null,
        reserved: null,
        itemNumber: null,
        tagIds: [],
    },
    navigation: {
        items: [],
    },
    weekHighlights: {
        items: null,
    },
    trendy: {
        items: null,
    },
    selectionValues: {
        color: null,
        brand: null,
        state: null,
        size: null,
        webShop: null,
        catalogClothingFit: null,
    },
};
