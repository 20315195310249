import React, { ReactElement } from 'react';

interface MetaIconProps {
    width?: number;
    height?: number;
    color?: string;
}

export const MetaIcon = ({ width, height, color }: MetaIconProps): ReactElement => {
    return (
        <svg
            width={width}
            height={height}
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
        >
            <g fill={color}>
                <path d='M2.949 11.87c0 1.035.229 1.83.528 2.31.392.63.976.897 1.573.897.769 0 1.472-.19 2.828-2.051 1.086-1.492 2.366-3.586 3.227-4.899l1.458-2.224c1.013-1.544 2.185-3.261 3.53-4.425C17.19.528 18.374 0 19.566 0c2 0 3.905 1.15 5.363 3.309 1.596 2.363 2.371 5.34 2.371 8.413 0 1.826-.363 3.168-.98 4.228-.596 1.026-1.758 2.05-3.713 2.05v-2.923c1.674 0 2.092-1.527 2.092-3.274 0-2.49-.585-5.253-1.873-7.228-.915-1.4-2.1-2.256-3.403-2.256-1.41 0-2.544 1.056-3.82 2.938-.677 1-1.373 2.218-2.154 3.593l-.86 1.513c-1.728 3.04-2.166 3.734-3.03 4.877C8.045 17.24 6.752 18 5.05 18c-2.019 0-3.296-.868-4.086-2.176C.317 14.758 0 13.36 0 11.766l2.949.105Z' />
                <path d='M2.325 3.515C3.677 1.447 5.628 0 7.865 0c1.297 0 2.585.38 3.93 1.471 1.472 1.192 3.04 3.155 4.997 6.39l.701 1.162c1.694 2.8 2.658 4.241 3.221 4.92.726.873 1.234 1.134 1.894 1.134 1.673 0 2.09-1.527 2.09-3.274l2.602-.081c0 1.826-.363 3.168-.98 4.228-.596 1.026-1.758 2.05-3.712 2.05-1.216 0-2.292-.262-3.483-1.377-.915-.856-1.985-2.376-2.808-3.742l-2.448-4.06c-1.229-2.038-2.356-3.557-3.008-4.245-.702-.74-1.604-1.633-3.043-1.633-1.165 0-2.154.812-2.982 2.053l-2.51-1.48Z' />
                <path d='M7.818 2.943c-1.165 0-2.154.812-2.982 2.053C3.666 6.75 2.95 9.362 2.95 11.87c0 1.034.229 1.829.528 2.31L.963 15.823C.317 14.758 0 13.36 0 11.766c0-2.898.801-5.918 2.325-8.25C3.677 1.445 5.628 0 7.865 0l-.047 2.943ZM33.032.57h3.411l5.8 10.415 5.8-10.416h3.338v17.114h-2.783V4.567l-5.087 9.083h-2.61l-5.086-9.083v13.116h-2.783V.57ZM60.1 6.95c-1.996 0-3.198 1.491-3.485 3.337h6.772c-.14-1.901-1.244-3.337-3.287-3.337Zm-6.244 4.39c0-3.885 2.529-6.713 6.292-6.713 3.702 0 5.912 2.792 5.912 6.92v.758h-9.445c.335 2.008 1.677 3.362 3.842 3.362 1.727 0 2.806-.523 3.83-1.48l1.478 1.797c-1.393 1.272-3.165 2.005-5.407 2.005-4.074 0-6.502-2.949-6.502-6.65Zm15.578-4.145h-2.561V4.934h2.56v-3.74h2.686v3.74h3.891v2.261H72.12v5.733c0 1.957.63 2.653 2.18 2.653.707 0 1.112-.06 1.711-.16v2.238a8.051 8.051 0 0 1-2.229.305c-2.898 0-4.347-1.572-4.347-4.718v-6.05Zm17.881 2.2c-.539-1.351-1.742-2.347-3.51-2.347-2.297 0-3.768 1.619-3.768 4.254 0 2.57 1.354 4.267 3.658 4.267 1.812 0 3.105-1.046 3.62-2.348V9.395ZM90 17.683h-2.635V15.9c-.737 1.05-2.078 2.09-4.249 2.09-3.492 0-5.825-2.902-5.825-6.687 0-3.82 2.39-6.675 5.973-6.675 1.772 0 3.162.703 4.1 1.945V4.934H90v12.75Z' />
            </g>
        </svg>
    );
};

MetaIcon.defaultProps = {
    width: 90,
    height: 18,
    color: '#ffffff',
};
