import React, { ReactElement, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { MenuList } from '@mui/material';

import { useStore } from 'src/mst/StoreProvider';

import { useMainProductNavigationMenuStyles } from './MainProductNavigationMenuStyles';
import { MainNavigationMenuItems } from './MainNavigationMenuItems';

export const MainProductnavigationMenu = observer((): ReactElement => {
    const classes = useMainProductNavigationMenuStyles({});
    const [selectedCategory, selectCategory] = useState<string>('');

    const {
        navigation: { items },
    } = useStore();

    useEffect(() => {
        const listenerCb = (): void => {
            selectCategory('');
        };
        document.addEventListener('click', listenerCb);
        return () => {
            removeEventListener('click', listenerCb);
        };
    }, []);

    return (
        <MenuList className={classes.navigationContainer}>
            {items.map((menu, index) => {
                return (
                    <div key={menu.id} className={classes.targetGroupContainer}>
                        <MainNavigationMenuItems
                            targetMenuIndex={index}
                            selectedCategory={selectedCategory}
                            selectCategory={selectCategory}
                            {...menu}
                        />
                    </div>
                );
            })}
        </MenuList>
    );
});
