import { makeStyles } from '@mui/styles';

import { THEME_COLORS } from 'src/theming/theme';

export const useMultipleSelectFilterStyles = makeStyles(() => {
    return {
        filterContainer: {
            '& .MuiInputLabel-root': {
                paddingTop: 0,
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: THEME_COLORS.INFO_WARNING_DARK_COLOR,
            },
        },
    };
});
