import React, { ReactElement, FC, BaseSyntheticEvent } from 'react';

import { IconButton } from '@mui/material';

import { withTooltip } from 'src/shared/hocs';

interface IconProps {
    width?: number;
    height?: number;
}

type Size = 'small' | 'medium' | 'large';

interface IconButtonWithTooltipProps {
    Icon: FC<IconProps>;
    ariaLabel?: string;
    buttonClassName?: string;
    onClick: () => void;
    disabled?: boolean;
    size?: Size;
}

export const IconButtonWithTooltip = withTooltip(function ({
    Icon,
    ariaLabel,
    buttonClassName,
    onClick,
    disabled,
    size,
}: IconButtonWithTooltipProps): ReactElement {
    const handleClick = (e: BaseSyntheticEvent): void => {
        e.stopPropagation();
        onClick();
    };

    return (
        <IconButton
            disabled={disabled}
            className={buttonClassName}
            size={size}
            color='inherit'
            onClick={handleClick}
            aria-label={ariaLabel}
        >
            <Icon />
        </IconButton>
    );
});

IconButtonWithTooltip.defaultProps = {
    size: 'small',
};
