import React, { useState, ReactElement, KeyboardEvent, MouseEvent } from 'react';
import { observer } from 'mobx-react-lite';

import { IconButton, SwipeableDrawer, Box, CircularProgress } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import { LanguageSelector, StoreLinks } from 'src/components/base';
import { SearchField, BaseInputWrapper } from 'src/components/fields';
import { centerAlignByFlex } from 'src/theming/mixins';
import { useStore } from 'src/mst/StoreProvider';

import { useTabletMenuStyles } from './TabletMenuStyles';
import { MainProductTabletNavigationMenu } from '../MainProductTabletNavigationMenu';

interface TabletMenuProps {
    isTargetGroupsLoading: boolean;
}
export const TabletMenu = observer(
    ({ isTargetGroupsLoading }: TabletMenuProps): ReactElement => {
        const [open, setOpen] = useState<boolean>(false);
        const classes = useTabletMenuStyles();

        const root = useStore();
        const { globalSearchValue } = root;

        const toggleDrawer = (open: boolean) => (event: KeyboardEvent | MouseEvent) => {
            if (
                event &&
                event.type === 'keydown' &&
                ((event as KeyboardEvent).key === 'Tab' ||
                    (event as KeyboardEvent).key === 'Shift')
            ) {
                return;
            }

            setOpen(open);
        };

        const onChange = (_: string, value: string): void => {
            root.setSearchValue(value);
        };

        const list = (): ReactElement => (
            <Box role='presentation' className={classes.drawerContentWrapper}>
                <div className={classes.drawerHeader}>
                    <div className={classes.drawerLanguageSelectorWrapper}>
                        <LanguageSelector />
                    </div>
                    <div className={classes.drawerLogoWrapper}>
                        <img src='/static/images/logo.png' alt='Ekodise webshop' />
                    </div>
                    <IconButton
                        className={classes.clsButton}
                        onClick={toggleDrawer(false)}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className={classes.searchFieldWrapper}>
                    <BaseInputWrapper id='drawer-search-field'>
                        <SearchField
                            id='drawer-search-field'
                            name='global-search-field'
                            value={globalSearchValue}
                            onChange={onChange}
                        />
                    </BaseInputWrapper>
                </div>

                <div className={classes.navigation}>
                    {!isTargetGroupsLoading && (
                        <MainProductTabletNavigationMenu setOpen={setOpen} />
                    )}
                    {isTargetGroupsLoading && (
                        <Box
                            sx={{ ...centerAlignByFlex(), width: '100%', height: '100%' }}
                        >
                            <CircularProgress color='secondary' />
                        </Box>
                    )}
                </div>

                <StoreLinks injectedClassName={classes.linksWrapper} />
            </Box>
        );

        return (
            <div>
                <IconButton className={classes.burgerButton} onClick={toggleDrawer(true)}>
                    <MenuIcon />
                </IconButton>
                <SwipeableDrawer
                    anchor='right'
                    open={open}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                    sx={{ zIndex: 200000 }}
                >
                    {list()}
                </SwipeableDrawer>
            </div>
        );
    }
);
