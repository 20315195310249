import React, { ReactElement } from 'react';

interface InstagramIconProps {
    size?: number;
    color?: string;
}

export const InstagramIcon = ({ size, color }: InstagramIconProps): ReactElement => {
    return (
        <svg width={size} height={size} fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M15.34 3.46a1.2 1.2 0 1 0 0 2.4 1.2 1.2 0 0 0 0-2.4Zm4.6 2.42a7.588 7.588 0 0 0-.46-2.43 4.94 4.94 0 0 0-1.16-1.77A4.7 4.7 0 0 0 16.55.53a7.3 7.3 0 0 0-2.43-.47C13.06 0 12.72 0 10 0S6.94 0 5.88.06a7.3 7.3 0 0 0-2.43.47 4.78 4.78 0 0 0-1.77 1.15A4.7 4.7 0 0 0 .53 3.45a7.3 7.3 0 0 0-.47 2.43C0 6.94 0 7.28 0 10s0 3.06.06 4.12a7.3 7.3 0 0 0 .47 2.43 4.7 4.7 0 0 0 1.15 1.77 4.78 4.78 0 0 0 1.77 1.15 7.3 7.3 0 0 0 2.43.47C6.94 20 7.28 20 10 20s3.06 0 4.12-.06a7.3 7.3 0 0 0 2.43-.47 4.7 4.7 0 0 0 1.77-1.15 4.85 4.85 0 0 0 1.16-1.77c.285-.78.44-1.6.46-2.43 0-1.06.06-1.4.06-4.12s0-3.06-.06-4.12ZM18.14 14a5.61 5.61 0 0 1-.34 1.86 3.06 3.06 0 0 1-.75 1.15c-.324.33-.717.586-1.15.75a5.61 5.61 0 0 1-1.86.34c-1 .05-1.37.06-4 .06s-3 0-4-.06a5.73 5.73 0 0 1-1.94-.3 3.27 3.27 0 0 1-1.1-.75 3 3 0 0 1-.74-1.15 5.54 5.54 0 0 1-.4-1.9c0-1-.06-1.37-.06-4s0-3 .06-4a5.54 5.54 0 0 1 .35-1.9A3 3 0 0 1 3 3a3.14 3.14 0 0 1 1.1-.8A5.73 5.73 0 0 1 6 1.86c1 0 1.37-.06 4-.06s3 0 4 .06a5.61 5.61 0 0 1 1.86.34 3.06 3.06 0 0 1 1.19.8c.328.307.584.683.75 1.1.222.609.337 1.252.34 1.9.05 1 .06 1.37.06 4s-.01 3-.06 4ZM10 4.87A5.13 5.13 0 1 0 15.14 10 5.12 5.12 0 0 0 10 4.87Zm0 8.46a3.33 3.33 0 1 1 0-6.66 3.33 3.33 0 0 1 0 6.66Z'
                fill={color}
            />
        </svg>
    );
};

InstagramIcon.defaultProps = {
    size: 20,
    color: '#ffffff',
};
