import React, {
    BaseSyntheticEvent,
    ReactElement,
    useState,
    useEffect,
    useRef,
    Dispatch,
    SetStateAction,
} from 'react';
import clsx from 'clsx';
import { useNavigate, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { MenuList, MenuItem } from '@mui/material';

import { AppRoutes } from 'src/routing/appRoutes';

import { MainNavigationMenuDetailedCategories } from './MainNavigationMenuDetailedCategories';
import { NavMenuItem } from './NavMenuItem';
import { useMainProductNavigationMenuStyles } from './MainProductNavigationMenuStyles';

interface DropdownProps {
    open: boolean;
    selectedMenuId: string;
    selectCategory: Dispatch<SetStateAction<string>>;
    submenus?: NavMenuItem[];
}

export const MainNavigationMenuItemsDropdown = ({
    submenus,
    open,
    selectedMenuId,
    selectCategory,
}: DropdownProps): ReactElement => {
    const ref = useRef<HTMLUListElement>(null);
    const classes = useMainProductNavigationMenuStyles({});
    const { t } = useTranslation();

    const navigate = useNavigate();

    const [selectedSubcategory, selectSubcategory] = useState<string>('');

    const handleSelectSubCategory =
        (subCategoryId: string, openDetailedCategories: boolean) =>
        (e: BaseSyntheticEvent) => {
            e.stopPropagation();
            if (openDetailedCategories && subCategoryId !== 'All') {
                selectSubcategory(subCategoryId);
            } else {
                selectCategory('');
                navigate(
                    subCategoryId === 'All'
                        ? generatePath(AppRoutes.goodsListMenu, {
                              menuId: selectedMenuId,
                          })
                        : generatePath(AppRoutes.goodsListCategory, {
                              menuId: selectedMenuId,
                              categoryId: subCategoryId,
                          })
                );
            }
        };

    const subCategoryItems = submenus?.find(
        (item) => item.id === selectedSubcategory
    )?.submenu;

    const isDetailedCategoriesSectionShown =
        selectedSubcategory &&
        Array.isArray(subCategoryItems) &&
        subCategoryItems.length > 0;

    useEffect(() => {
        if (!open) {
            selectSubcategory('');
        }
    }, [open]);

    return (
        <div
            className={clsx(
                classes.dropdown,
                open ? classes.scaleShow : classes.scaleHide
            )}
        >
            <MenuList ref={ref}>
                {submenus?.map((submenu, index) => (
                    <MenuItem
                        tabIndex={open ? 0 : -1}
                        key={index}
                        value={submenu.id}
                        onClick={handleSelectSubCategory(
                            submenu.id,
                            Array.isArray(submenu.submenu) && submenu.submenu.length > 0
                        )}
                        aria-controls={open ? 'detailedCategories' : undefined}
                        aria-haspopup='true'
                        aria-expanded={open ? 'true' : false}
                        className={clsx(
                            classes.dropdownItem,
                            selectedSubcategory === submenu.id
                                ? classes.activeTextColor
                                : '',
                            submenus.length > 3 ? classes.borderBottomNone : ''
                        )}
                    >
                        {t(submenu.title)}
                    </MenuItem>
                ))}
            </MenuList>
            {ref.current && (
                <div
                    className={clsx(
                        classes.detailedCategories,
                        isDetailedCategoriesSectionShown ? classes.show : classes.hide
                    )}
                    style={{ maxHeight: ref?.current?.offsetHeight }}
                >
                    <MainNavigationMenuDetailedCategories
                        selectedSubcategory={selectedSubcategory}
                        items={subCategoryItems}
                        selectedMenuId={selectedMenuId}
                    />
                </div>
            )}
        </div>
    );
};
