import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';

import { customTheme } from 'src/theming/theme';
import App from 'src/components/App/App';
import { SuspenseProgress } from './components/base';
import { ConnectivityIndicatorInfrastructure } from 'src/lib/connectivity-indicator/connectivity-indicator-infrastructure';
import { HttpInfrastructure } from 'src/lib/http-client/http-infrastructure';
import { StoreProvider } from 'src/mst/StoreProvider';
import i18n from 'src/i18-next.config';
import { CustomRouter } from 'src/routing/CustomRouter';
import { history } from 'src/routing/history';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            structuralSharing: false,
        },
    },
});

ReactDOM.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <CustomRouter history={history}>
                <ConnectivityIndicatorInfrastructure>
                    <HttpInfrastructure>
                        <QueryClientProvider client={queryClient}>
                            <ReactQueryDevtools initialIsOpen={false} />
                            <StoreProvider>
                                <ThemeProvider theme={customTheme}>
                                    <StyledEngineProvider injectFirst>
                                        <Suspense fallback={<SuspenseProgress />}>
                                            <App />
                                            <CssBaseline />
                                        </Suspense>
                                    </StyledEngineProvider>
                                </ThemeProvider>
                            </StoreProvider>
                        </QueryClientProvider>
                    </HttpInfrastructure>
                </ConnectivityIndicatorInfrastructure>
            </CustomRouter>
        </I18nextProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
