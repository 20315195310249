import React, { ReactElement, useState, useCallback } from 'react';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { Box, Button } from '@mui/material';

import { TextField, BaseInputWrapper } from 'src/components/fields';
import PasswordStrengthBar from 'src/lib/passwordStrengthBar/PasswordStrengthBar';
import { useDebounce } from 'src/lib/custom-hooks/use-debounce';
import { useStore } from 'src/mst/StoreProvider';
import { Loader } from 'src/components/base';
import { ResetPasswordDto, ChangePasswordDto } from 'src/shared/types';
import { AppRoutes } from 'src/routing/appRoutes';

import { useCreateNewPasswordFormStyles } from './CreateNewPasswordFormStyles';
import { createPasswordValidationSchema } from './CreateNewPasswordFormValidationSchema';
import { AuthModuleSections } from 'src/authentication/constants';

interface CreateNewPasswordFormType {
    type: string;
    token: string;
    oldPassword?: string;
    password?: string;
    repeatPassword?: string;
    onSuccess?: () => void;
}

const langNS = ['authentication', 'personalInfo', 'fieldErrors', 'serverErrors'];

export const CreateNewPasswordForm = ({
    password,
    repeatPassword,
    onSuccess,
    type,
    token,
}: CreateNewPasswordFormType): ReactElement => {
    const classes = useCreateNewPasswordFormStyles();
    const { t } = useTranslation(langNS);

    const { authentication } = useStore();
    const navigate = useNavigate();

    const { mutateAsync: resetPassword, isLoading: isResetLoading } = useMutation(
        authentication.resetPassword,
        {
            onSuccess: () => {
                if (typeof onSuccess === 'function') {
                    onSuccess();
                }
                navigate(AppRoutes.home);
                authentication.setAuthModule(AuthModuleSections.signIn);
                toast.success(t('successResetPasswordMessage', { ns: 'authentication' }));
            },
            onError: () => {
                toast.error(t('unknownError', { ns: 'serverErrors' }));
            },
        }
    );

    const { mutateAsync: changePassword, isLoading: isChangeLoading } = useMutation(
        authentication.changePassword,
        {
            onSuccess: () => {
                toast.success(
                    t('successChangePasswordMessage', { ns: 'authentication' })
                );
                if (typeof onSuccess === 'function') {
                    onSuccess();
                }
            },
            onError: () => {
                toast.error(t('unknownError', { ns: 'serverErrors' }));
            },
        }
    );

    const {
        values,
        errors,
        handleSubmit,
        touched,
        dirty,
        isValid,
        setFieldValue,
        setFieldTouched,
    } = useFormik<CreateNewPasswordFormType>({
        initialValues: {
            token,
            type,
            oldPassword: '',
            password: password || '',
            repeatPassword: repeatPassword || '',
        },
        validationSchema: createPasswordValidationSchema,
        onSubmit: (formValues) => {
            if (formValues.type === 'change') {
                const body: ChangePasswordDto = {
                    oldPassword: formValues.oldPassword,
                    newPassword: formValues.password,
                };
                changePassword(body);
            } else {
                const body: ResetPasswordDto = {
                    token: formValues.token,
                    password: formValues.password,
                };
                resetPassword(body);
            }
        },
    });

    const [isStrong, setStrong] = useState<boolean>(false);

    const {
        password: passwordValue,
        repeatPassword: repeatPasswordValue,
        oldPassword: oldPasswordValue,
    } = values;

    const debouncedPassword = useDebounce(passwordValue);

    const handleSetStrong = useCallback((value: boolean): void => {
        setStrong(value);
    }, []);

    const handleChange = useCallback((name: string, value: string): void => {
        setFieldTouched(name);
        setFieldValue(name, value);
    }, []);

    const {
        password: passwordError,
        repeatPassword: repeatPasswordError,
        oldPassword: oldPasswordError,
    } = errors;

    return (
        <form autoComplete='off' className={classes.container} onSubmit={handleSubmit}>
            {type === 'change' && (
                <Box mb={3} className={classes.inputWrapper}>
                    <BaseInputWrapper
                        id='oldPassword'
                        label='authentication:oldPassword'
                        languageNamespaces={langNS}
                        error={touched.oldPassword ? Boolean(oldPasswordError) : false}
                        helperText={
                            touched.oldPassword && oldPasswordError
                                ? `fieldErrors:${oldPasswordError}`
                                : ''
                        }
                    >
                        <TextField
                            id='oldPassword'
                            name='oldPassword'
                            inputProps={{
                                type: 'password',
                                autoComplete: 'old-password',
                            }}
                            onChange={handleChange}
                            value={oldPasswordValue}
                            error={
                                touched.oldPassword ? Boolean(oldPasswordError) : false
                            }
                        />
                    </BaseInputWrapper>
                </Box>
            )}

            <Box mb={3} className={classes.inputWrapper}>
                <input type='password' style={{ display: 'none' }} />
                <BaseInputWrapper
                    id='password-label'
                    label='authentication:password'
                    languageNamespaces={langNS}
                    error={touched.password ? Boolean(passwordError) : false}
                    helperText={
                        touched.password && passwordError
                            ? `fieldErrors:${passwordError}`
                            : ''
                    }
                >
                    <TextField
                        id='password'
                        name='password'
                        inputProps={{ type: 'password', autoComplete: 'new-password' }}
                        onChange={handleChange}
                        value={passwordValue}
                        error={touched.password ? Boolean(passwordError) : false}
                    />
                </BaseInputWrapper>
                <PasswordStrengthBar
                    password={debouncedPassword}
                    onStrong={handleSetStrong}
                    pathToCheckPassword='users/check-password-strength'
                />
            </Box>
            <Box mb={3} className={classes.inputWrapper}>
                <BaseInputWrapper
                    id='repeat-password-label'
                    label='authentication:repeatPassword'
                    languageNamespaces={langNS}
                    error={touched.repeatPassword ? Boolean(repeatPasswordError) : false}
                    helperText={
                        touched.repeatPassword && repeatPasswordError
                            ? `fieldErrors:${repeatPasswordError}`
                            : ''
                    }
                >
                    <TextField
                        id='repeatPassword'
                        name='repeatPassword'
                        inputProps={{ type: 'password', autoComplete: 'new-password' }}
                        onChange={handleChange}
                        value={repeatPasswordValue}
                        error={
                            touched.repeatPassword ? Boolean(repeatPasswordError) : false
                        }
                    />
                </BaseInputWrapper>
            </Box>
            <Button
                fullWidth
                variant='contained'
                color='secondary'
                type='submit'
                disabled={
                    !isValid || !dirty || !isStrong || isResetLoading || isChangeLoading
                }
                startIcon={(isResetLoading || isChangeLoading) && <Loader />}
            >
                {t('personalInfo:createNewPassword')}
            </Button>
        </form>
    );
};
