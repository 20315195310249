import React, { ReactElement, FC } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { useDateString } from 'src/shared/hooks';
import { ImagePreview } from 'src/shared/types';

import { RepresentationSection } from '../RepresentationSection';
import { HeaderInfoItem, StaticBodyContent, ResultBlock } from './components';
import { useExtendedProductCardStyles } from './ExtendedProductCardStyles';
import { ItemRefundDto } from 'src/pages';

export interface ExtendedItemCard<M> {
    id: string;
    reserved: boolean;
    adddedToCart: boolean;
    addedToBookingList: boolean;
    localOnly: boolean;
    isNewGood: boolean;
    shippingAvailable: boolean;
    name: string;
    price: number;
    freeShippingPrice: number;
    amount?: number;
    color?: string;
    size?: string | number;
    stock?: number;
    previewImage?: ImagePreview;
    imageLink?: string;
    itemModel?: M;
    refund?: ItemRefundDto;
}

export interface ExtendedProductCardProps<T, M> {
    id?: string;
    shopId: string;
    shop: string;
    location: string;
    workHours?: string;
    items: ExtendedItemCard<M>[];
    renderDynamicComponent?: (params: ExtendedItemCard<M> & T) => ReactElement;
    withResultBlock?: boolean;
    renderDynamicComponentProps?: T;
    shippingPrice?: number;
    freeShippingPrice?: number;
    injectedMediaContainerClass?: string;
    AmountInputField?: FC<{ item: M }>;
    reservedFrom?: string;
    reservedTo?: string;
    hideDeliveryTime?: boolean;
    hideAmount?: boolean;
    hideShippingInfo?: boolean;
    hideLabels?: boolean;
    reservationTitle?: string;
    disableImageInteractivity?: boolean;
    filterItemsCb?: (item: ExtendedItemCard<M>) => boolean;
    hideBorder?: boolean;
}

export const ExtendedProductCard = function <T, M>({
    reservedFrom,
    reservedTo,
    shopId,
    shop,
    location,
    workHours,
    items,
    renderDynamicComponent,
    renderDynamicComponentProps,
    withResultBlock,
    shippingPrice,
    freeShippingPrice,
    injectedMediaContainerClass,
    AmountInputField,
    hideDeliveryTime,
    hideAmount,
    hideShippingInfo,
    hideLabels,
    reservationTitle,
    disableImageInteractivity,
    hideBorder,
    filterItemsCb,
}: ExtendedProductCardProps<T, M>): ReactElement {
    const classes = useExtendedProductCardStyles();
    const { t } = useTranslation(['extendedProductCard', 'formatted-values']);

    const formattedReservedFrom = useDateString({ date: reservedFrom });
    const formattedReservedTo = useDateString({ date: reservedTo });

    return (
        <RepresentationSection hideBorder={hideBorder}>
            <div className={classes.header}>
                {shop && <HeaderInfoItem title='extendedProductCard:shop' value={shop} />}
                {location && (
                    <HeaderInfoItem
                        title='extendedProductCard:location'
                        value={location}
                    />
                )}
                {workHours && (
                    <HeaderInfoItem
                        title='extendedProductCard:workHours'
                        value={workHours}
                    />
                )}
                {reservedFrom && (
                    <HeaderInfoItem
                        title={reservationTitle}
                        value={`${formattedReservedFrom} - ${formattedReservedTo}`.toUpperCase()}
                    />
                )}
                {!hideShippingInfo && !hideDeliveryTime && (
                    <HeaderInfoItem
                        title='extendedProductCard:deliveryTime'
                        value={t('extendedProductCard:deliveryTimeMessage')}
                    />
                )}
                {!hideShippingInfo && typeof freeShippingPrice === 'number' && (
                    <HeaderInfoItem
                        title='extendedProductCard:shippingMessage'
                        value={t('extendedProductCard:freeShipping', {
                            value: t('formatted-values:formattedCurrency', {
                                currency: freeShippingPrice,
                            }),
                        })}
                    />
                )}
            </div>
            <div className={classes.itemsList}>
                {(filterItemsCb ? items.filter(filterItemsCb) : items).map(
                    (
                        {
                            id,
                            reserved,
                            adddedToCart,
                            addedToBookingList,
                            price,
                            freeShippingPrice,
                            amount,
                            color,
                            name,
                            size,
                            localOnly,
                            isNewGood,
                            shippingAvailable,
                            stock,
                            previewImage,
                            imageLink,
                            itemModel,
                            refund,
                        },
                        index
                    ) => {
                        return (
                            <div key={id || index} className={clsx(classes.itemBody)}>
                                <div className={classes.staticBodyContent}>
                                    <StaticBodyContent<M>
                                        itemId={id}
                                        localOnly={!shippingAvailable}
                                        reserved={reserved}
                                        isNewGood={isNewGood}
                                        name={name}
                                        price={price}
                                        amount={amount}
                                        color={color}
                                        size={size}
                                        AmountInputField={AmountInputField}
                                        injectedMediaContainerClass={
                                            injectedMediaContainerClass
                                        }
                                        hideAmount={hideAmount}
                                        hideLabels={hideLabels}
                                        previewImage={previewImage}
                                        imageLink={imageLink}
                                        disableImageInteractivity={
                                            disableImageInteractivity
                                        }
                                        itemModel={itemModel}
                                        refund={refund}
                                    />
                                </div>
                                {renderDynamicComponent && (
                                    <div className={classes.dynamicBodyContent}>
                                        {renderDynamicComponent({
                                            id,
                                            shopId,
                                            reserved,
                                            adddedToCart,
                                            addedToBookingList,
                                            price,
                                            freeShippingPrice,
                                            amount,
                                            color,
                                            name,
                                            size,
                                            localOnly,
                                            isNewGood,
                                            shippingAvailable,
                                            stock,
                                            ...renderDynamicComponentProps,
                                            refund,
                                        })}
                                    </div>
                                )}
                            </div>
                        );
                    }
                )}
                {withResultBlock && (
                    <ResultBlock
                        freeShippingPrice={freeShippingPrice}
                        shippingPrice={shippingPrice}
                        items={items}
                    />
                )}
            </div>
        </RepresentationSection>
    );
};

ExtendedProductCard.defaultProps = {
    reservationTitle: 'extendedProductCard:reservedTime',
};
