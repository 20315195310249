import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';

import { SelectField, BaseInputWrapper } from 'src/components/fields';
import { useStore } from 'src/mst/StoreProvider';

import { SelectableItem } from 'src/shared/types';
import { SelectionValues } from 'src/shared/constants';

import { useMultipleSelectFilterStyles } from './MultipleSelectFilterStyles';

export interface MultipleSelectFilterProps {
    label: string;
    id: string;
    name: string;
    languageNamespaces: string[];
    placeholder?: string;
    popperMinWidth?: number;
}

export const MultipleSelectFilter = observer(
    ({
        id,
        label,
        name,
        languageNamespaces,
    }: MultipleSelectFilterProps): ReactElement => {
        const classes = useMultipleSelectFilterStyles();
        const { i18n } = useTranslation();
        const { menuId, subCategoryId } = useParams();
        const { productsList, selectionValues } = useStore();

        const items: SelectableItem[] | null =
            selectionValues.getSelectionItemsByName(name);

        const handleChange = (name: string, values: string[]): void => {
            if (Array.isArray(items) && items.length > 0) {
                const mappedValues = values.map((value) => {
                    const foundValue = items.find((item) => item.value === value);
                    return {
                        filter: name,
                        titles: [() => foundValue?.title || ''] || [],
                        value: foundValue?.value || '',
                    };
                });
                productsList.setFilterValues(name, mappedValues);
            }
        };

        const currentValues = productsList
            .getFilterValues(name)
            .map((item) => item.value);

        useEffect(() => {
            if (name === SelectionValues.size) {
                selectionValues.setSelectionValues(SelectionValues.size, null);
                productsList.setFilterValues(SelectionValues.size, []);
            }
        }, [menuId, subCategoryId, name]);

        useEffect(() => {
            selectionValues.setSelectionValues(name, null);
        }, [i18n.language]);

        useEffect(() => {
            if (items === null) {
                if (name === SelectionValues.size) {
                    productsList.setFilterValues(SelectionValues.size, []);
                    selectionValues.fetchSizeSelectionValues(
                        menuId || '',
                        subCategoryId || ''
                    );
                } else {
                    selectionValues.fetchSelectionValues(name);
                }
            }
        }, [items, name]);

        return (
            <div className={classes.filterContainer}>
                <BaseInputWrapper
                    id={id}
                    label={label}
                    languageNamespaces={languageNamespaces}
                >
                    <SelectField
                        multiple
                        id={id}
                        languageNamespaces={languageNamespaces}
                        tLabelKey={label}
                        name={name}
                        value={currentValues}
                        items={items || []}
                        handleChange={handleChange}
                    />
                </BaseInputWrapper>
            </div>
        );
    }
);
