import {
    applySnapshot,
    getSnapshot,
    Instance,
    SnapshotOut,
    types,
    flow,
    getEnv,
} from 'mobx-state-tree';
import qs from 'query-string';

import { ProductsListFilterMst, ProductsFilterItem } from 'src/mst/customTypes';
import { SortOptions } from 'src/shared/constants';
import { SelectableItem, ProductsListDto } from 'src/shared/types';
import { Product } from '../product';
import { prepareQueryStringForProductsList } from './prepareQueryStringForProductsList';

export const ProductsList = types
    .model('ProductsList', {
        filters: types.array(ProductsListFilterMst),
        sale: types.boolean,
        sorting: types.maybeNull(types.string),
        items: types.array(Product),
        count: types.number,
    })
    .actions((self) => {
        let initialState: IProductsListSnapshotOut;

        const {
            env: { httpClient },
        } = getEnv(self);

        return {
            afterCreate(): void {
                initialState = getSnapshot(self);
            },

            resetStore(): void {
                applySnapshot(self, initialState);
            },
            updateFilterTitles(name: string, values: SelectableItem[] | null): void {
                if (values === null) {
                    return;
                }
                const newValues = self.filters.map((filter) => {
                    if (filter.filter !== name) {
                        return filter;
                    }
                    const newFilterItemTitle = values.find(
                        (item) => item.value === filter.value
                    )?.title;
                    if (typeof newFilterItemTitle === 'string') {
                        return {
                            ...filter,
                            titles: [() => newFilterItemTitle],
                        };
                    }
                    return filter;
                });
                applySnapshot(self, { ...self, filters: newValues });
            },
            setFilterValues(name: string, values: ProductsFilterItem[]): void {
                applySnapshot(self, {
                    ...self,
                    filters: [
                        ...self.filters.filter(
                            (item) =>
                                item.filter !== name ||
                                (item.filter === name &&
                                    values.some(
                                        (newItem) => newItem.value === item.value
                                    ))
                        ),
                        ...values.filter(
                            (item) =>
                                !self.filters.some(
                                    (existItem) =>
                                        existItem.value === item.value &&
                                        existItem.filter === item.filter
                                )
                        ),
                    ],
                });
            },
            removeFilterValue(name: string, value: string): void {
                applySnapshot(self, {
                    ...self,
                    filters: [
                        ...self.filters.filter(
                            (item) =>
                                (item.filter === name && item.value !== value) ||
                                item.filter !== name
                        ),
                    ],
                });
            },
            setToggableFilterValue(name: string, value: boolean): void {
                applySnapshot(self, { ...self, [name]: value });
            },
            setSorting(value: SortOptions | null): void {
                self.sorting = value;
            },
            fetchProductsList: flow(function* (params) {
                const [
                    page,
                    globalSearchTerm,
                    targetGroup,
                    allowedCategory,
                    allowedSubCategory,
                    filters,
                    sorting,
                ] = params.queryKey;

                const queryParams = prepareQueryStringForProductsList({
                    page,
                    globalSearchTerm,
                    targetGroup,
                    allowedCategory,
                    allowedSubCategory,
                    filters,
                    sorting,
                });

                try {
                    const data: ProductsListDto = yield httpClient.get(
                        `web-shop/items/page?${qs.stringify(queryParams)}`
                    );
                    applySnapshot(self, {
                        ...self,
                        count: data.count,
                        items: data.result,
                    });
                } catch (err) {
                    applySnapshot(self, { ...self, count: 0, items: [] });
                    throw err;
                }
            }),
        };
    })
    .views((self) => {
        return {
            getFilterValues(filter: string): ProductsFilterItem[] {
                return [...self.filters.filter((item) => item.filter === filter)];
            },
            getSeparateFilterValue(name: string): boolean | null {
                if (name === 'sale') {
                    return self.sale;
                }
                return null;
            },
        };
    });

export type ProductsListModel = Instance<typeof ProductsList>;
export type IProductsListSnapshotOut = SnapshotOut<typeof ProductsList>;
